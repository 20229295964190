import React, {useState, useEffect} from 'react';
import {ExcelRenderer} from 'react-excel-renderer';
// import { putCensoMasivo } from '../utils/api';
import { Link } from 'react-router-dom'
import {Loading} from "components/Taki/Loading"
import logoExcel from 'assets/img/excel-logo.png';
import logoTaki from 'assets/img/logotakin.jpg';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import DropDown from "components/Taki/DropDown"
import Alert from '@material-ui/lab/Alert'
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Grid from "@material-ui/core/Grid";
//firebase
import 'firebase/database';
import 'firebase/auth';
import { useFirebaseApp, useUser } from 'reactfire';

import 'assets/css/taki.css';
import { findAllByDisplayValue } from '@testing-library/react';

const ExcelUpload = (props) => {

    const [excelData,setExcelData] = useState()
    const [cuentasDB,setCuentasDB] = useState()
    const [listaTiendas,setListaTiendas] = useState()
    const [tiendasSeleccionadas,setTiendasSeleccionadas] = useState("")
    const [mostrarDropDown,setMostrarDropDown] = useState(true)
    const [dataCategorias,setDataCategorias] = useState()
    const [dataTiendas,setDataTiendas] = useState()
    const [categorias,setCategorias] = useState()
    const [productos,setProductos] = useState()
    const [error,setError] = useState()
    const [success,setSuccess] = useState()
    const [datosActualizados,setDatosActualizados] = useState()
    const [fetchAsync,setFetchAsync] = useState(false)


    const firebase=useFirebaseApp();
    const user=useUser();
    var email=""
    if(user){
        email=user.email;
    }
    else{
        email=""
    }

    const fileHandler = (event) => {
        setError()
        if (tiendasSeleccionadas!=="" && tiendasSeleccionadas.length>0){
            let fileObj = event.target.files[0];
            if(fileObj.name==="PlantillaMenu.xlsx"){
                try{
                    ExcelRenderer(fileObj, (err, resp) => {
                        if(!err)
                        {
                            try{
                                setExcelData(resp)
                            }
                            catch{
                                setError('Por favor carga un archivo de Excel válido')
                            }
                        }
                        else{
                            setError('Por favor carga un archivo de Excel válido')
                        }
                    })
                }
                catch{
                    setError('Por favor carga un archivo de Excel válido')
                }
            }
            else{
                setError('El nombre del archivo que cargaste no coincide con el de la plantilla. Por favor asegúrate de que sean iguales.')
            }
        }
        else{
            setError("Por favor selecciona al menos una tienda en la que quieras cargar el menú")
        }
    }

    const addData = async (db,data) =>{
        await firebase.database().ref(db).update(data)
    }

    const agregarKeyProd = async (key) =>{
        console.log("key producto",key)
        await firebase.database().ref("productosDB/"+key).update(
            {
                "key":key
            }
        )
    }

    const addProducts = async(db,data,keyTienda)=>{
        if (data['categoria']){
            await firebase.database().ref(db).push(data).then(res=>{
                var product_key=res['path']['pieces_'][1]
                var json={}
                json[product_key]={
                    "key":product_key
                }
                agregarKeyProd(product_key)
                addData("tiendasDB/productos/"+keyTienda,json)
            })
        }
    }

    const agregarKeyCat = async (keyTienda,key) =>{
        await firebase.database().ref("tiendasDB/personaliza/"+keyTienda+"/cats/"+key).update(
            {
                "key":key
            }
        )
    }

    const addCategory = async(data,keyTienda)=>{
        var data2={
            "cat":data,
            "key":""
        }
        await firebase.database().ref("tiendasDB/personaliza/"+keyTienda+"/cats").push(data2).then(res =>{
            var keyCat=res['path']['pieces_'][4]
            agregarKeyCat(keyTienda,keyCat)
            actualizarProductosAsync()
        }
            
        )
    }

    useEffect(()=>{
        if (excelData){
            var errorShare=false
            var cats=[]
            var prods=[]
            for(var i=1;i<excelData.rows.length;i++){
                if(excelData.rows[i][0])
                {
                    cats.push(excelData.rows[i][0])
                    var json={
                        "categoria":excelData.rows[i][0],
                        "descripcion":excelData.rows[i][1],
                        "nombre":excelData.rows[i][2],
                        "palabrasclaves":excelData.rows[i][3],
                        "precio":excelData.rows[i][4]
                    }
                    prods.push(json)
                } 
            }
            setCategorias(cats)
            setProductos(prods)
        }
    },[excelData])

    const crearCategorias = async ()=>{
        let cats=[... new Set(categorias)];
        var crear=false
        setDatosActualizados(false)
        for(var i=0;i<cats.length;i++){
            for(var j=0;j<tiendasSeleccionadas.length;j++){
                var keyCat=dataCategorias[tiendasSeleccionadas[j]][cats[i]]
                if(!keyCat){
                    crear=true
                    var data=cats[i]
                    try{
                        addCategory(data,tiendasSeleccionadas[j])
                    }
                    catch{
                        setError("Error creando las categorías. Intenta nuevamente más tarde.")
                    }
                }
            }
        }
        if(!crear){
            actualizarProductosSync()
        }

    }

    useEffect(()=>{
        if(excelData){
            crearCategorias()
        }
    },[categorias])


    useEffect(()=>{
        if(tiendasSeleccionadas!=="" && tiendasSeleccionadas.length>0 && error==="Por favor selecciona al menos una tienda en la que quieras cargar el menú"){
            setError()
        }
    },[tiendasSeleccionadas,error])

    const fetchData = async (db,funcion)=>{
        await firebase.database().ref(db).on("value",snapshot =>{
            let allData = [];
            snapshot.forEach(snap => {
            allData.push(snap.val());
            });
            funcion(allData)
        })
    }



    const actualizarDatos= async ()=>{
        
        await fetchData("cuentasDB",setCuentasDB);
        await fetchData("tiendasDB",setDataTiendas);
        if(props.tienda){
            setTiendasSeleccionadas([props.tienda])
            setMostrarDropDown(false)
        }
    }

    const actualizarProductosAsync = async ()=>{
        console.log("asy")
        await firebase.database().ref("tiendasDB").on("value",snapshot =>{
            let allData = [];
            snapshot.forEach(snap => {
            allData.push(snap.val());
            });
            setDataTiendas(allData)
            setFetchAsync(true)
        })
    }

    useEffect(()=>{
        if(fetchAsync){
            actualizarProductosSync()
        }
    },[dataCategorias,fetchAsync])

    const actualizarProductosSync = ()=>{
        try{
            var prods=productos
            for(var i=0;i<prods.length;i++){
                for(var j=0;j<tiendasSeleccionadas.length;j++){
                    var keyCat=dataCategorias[tiendasSeleccionadas[j]][prods[i]['categoria']]
                    prods[i]['categoria']=keyCat
                    addProducts("productosDB",prods[i],tiendasSeleccionadas[j])
                    //console.log(productos[i])
                }   
            }
            setSuccess("Datos cargados con éxito")
        }
        catch{
            setError("Error cargando los productos")
        }
    }

    useEffect(()=>{
        actualizarDatos()
    },[])


    const updateDataCategorias = ()=>{
        if(dataTiendas && tiendasSeleccionadas.length>0){
            var json={}
            for(var i=0;i<tiendasSeleccionadas.length;i++){
                
                try{
                    var info=dataTiendas[1][tiendasSeleccionadas[i]]
                    var cats=info["cats"]
                    var inte={}
                    for(var j=0;j<Object.keys(cats).length;j++){
                        var keyCat=Object.keys(cats)[j]
                        inte[cats[keyCat]["cat"]]=keyCat
                    }
                    json[tiendasSeleccionadas]=inte   
                }
                catch{
                    json={}
                }
            }
            setDataCategorias(json)
        }
    }

    useEffect(()=>{
        console.log("update cats")
        updateDataCategorias()
    },[dataTiendas,tiendasSeleccionadas])

    useEffect(()=>{
        if (cuentasDB){
            var filtro = cuentasDB.filter(
                function(cuentasDB){ return cuentasDB.email === email }
            );
            var tiendas=[]
            filtro.map(el=>{
                tiendas.push(el.tiendas)
                return tiendas
            })
            setListaTiendas(tiendas)
        }
    },[cuentasDB,email])


    return ( 
            <Grid container justify="center" alignContent="center" alignItems="center" direction="row">
                <Grid item xs={12}>
                    <AdminNavbar tienda={tiendasSeleccionadas[0]}/>
                </Grid>
                <Grid item xs={6}>
                {listaTiendas?
                    <Card id="step">
                        <CardHeader color="warning">
                            {/* <CardIcon color="warning">
                                <CloudUpload />
                            </CardIcon> */}
                            <h2>Sistema de carga masiva de Menú</h2>
                        </CardHeader>
                        <CardBody>
                            <Grid container justify="center" alignItems="center" spacing={1}>
                                <Grid item xs={12}>
                                    <img src={logoTaki}  alt="logo" width="90" height="80"/>
                                </Grid>
                                {mostrarDropDown &&
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader text>
                                            <h5>1. Selecciona las tiendas en las que quieras cargar el menú:</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <DropDown seleccion={setTiendasSeleccionadas} listaTiendas={listaTiendas}/>
                                        </CardBody>
                                    </Card>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader text>
                                        {mostrarDropDown ?
                                         <h5>2. Descarga la plantilla presionando este botón:</h5>:
                                         <h5>1. Descarga la plantilla presionando este botón:</h5>
                                        }
                                        </CardHeader>
                                        <CardBody>
                                        <Link to={"/PlantillaMenu.xlsx"} target="_blank" download>
                                            <div>
                                                <img src={logoExcel}  alt="logo" width="90" height="80"/>
                                            </div>
                                        </Link>
                                        </CardBody>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                        <Card>
                                            <CardHeader text>
                                                {mostrarDropDown ?
                                                <h5>3. Sube la plantilla documentada:</h5>:
                                                <h5>2. Sube la plantilla documentada:</h5>
                                                }
                                            </CardHeader>
                                            <CardBody>
                                                <GridContainer justify="center" alignItems="center">
                                                    <GridItem xs={12}>
                                                        <label htmlFor="contained-button-file">
                                                            <Button
                                                                variant="contained"
                                                                color="info"
                                                                component="span"
                                                            >
                                                            Cargar informaciones
                                                            </Button>
                                                        </label>
                                                    </GridItem>
                                                    <GridItem xs={12}>
                                                        <input  className="input-file" type="file" id="contained-button-file" onChange={(e) => {fileHandler(e)}} />
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>
                                        </Card>

                                </Grid>
                                {error &&
                                <Grid item xs={12}>
                                    <br></br>
                                    <div id="alert">
                                        <Alert  severity="error">{error}</Alert>
                                    </div>
                                </Grid>}
                                {success &&
                                <Grid item xs={12}>
                                    <br></br>
                                    <div id="alert">
                                        <Alert  severity="success">{success}</Alert>
                                    </div>
                                </Grid>}
                            </Grid>
                        </CardBody>
                    </Card>:
                    <Loading/>}
                </Grid>
            </Grid>
     );
}
 
export default ExcelUpload;