import React, { useEffect, useState } from "react";

// import { fetchToken } from "utils/api"
import Alert from '@material-ui/lab/Alert'


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

//firebase
import 'firebase/auth';
import { useFirebaseApp } from 'reactfire';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);


export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [showPassword,setShowPassword] = useState(false)
  // const { setAuthTokens } = useAuth();
  const firebase=useFirebaseApp();

  useEffect(() => {
    
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);

  const submitUser = async () =>{
    try{
      await firebase.auth().signInWithEmailAndPassword(userName,password).then(res=>{
        setIsError(false)
      })
    }
    catch{
      setIsError(true)
    }
  }

  const togglePasswordVisibility = () =>{
    setShowPassword(!showPassword)
  }
  
  const classes = useStyles();
  return (
    <div id="contenedorLogin">
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}> 
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="warning"
              >
                <h4 className={classes.cardTitle} >Iniciar Sesión</h4>
              </CardHeader>
              <CardBody>
              <br></br>
              
                <CustomInput
                
                  labelText="Correo electrónico"
                  id="usuario"
                  formControlProps={{
                    fullWidth: true
                  }}
                  update={setUserName}
                  inputProps={
                    {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Contraseña"
                  id="contraseña"
                  formControlProps={{
                    fullWidth: true
                  }}
                  update={setPassword}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment onClick={togglePasswordVisibility} position="end">
                         {showPassword ? <Visibility className={classes.inputAdornmentIcon} /> : 
                         <VisibilityOff className={classes.inputAdornmentIcon}/>}
                      </InputAdornment>
                    ),
                    type: showPassword ? "text" : "password",
                    autoComplete: "off"
                  }}
                />
                <br/><br/>
                {isError && <Alert   variant="standard" severity="error">Tu contraseña o tu usuario son incorrectos. Por favor revísalos.</Alert>}
                <br></br>
                <br></br>
                <Button round color="info" onClick={submitUser}>
                    <div className="boton"> Iniciar Sesión</div>
                </Button>
              </CardBody>
                
            </Card>
          </form>
        </GridItem>
      </GridContainer>

    </div>
    </div>
  );
}
