import React from 'react';

import './App.css';
// import { AuthContext } from "./context/auth";
import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom'

import LoginPage from "pages/LoginPage"
import UploadExcel from 'pages/UploadExcel'
import { useUser } from 'reactfire'


function App(props) {
  const user = useUser();

  return (
      <div className="App">
        <Router>
            {!user ? 
            <div>
            <Route path="/login" component={LoginPage}/>
            <Redirect to="/login"/>
            </div>          
            :
            <div>
            <Route path="/cargar_menu" component={()=><UploadExcel tienda={props.tienda}/>}/>
            <Redirect to="/cargar_menu"/>
            </div>
        }
        </Router>
      </div>
  );
}

export default App;
