import React from "react";
// nodejs library to set properties for components

//firebase
import 'firebase/auth';
import { useFirebaseApp } from 'reactfire';

// core components
import Button from "components/CustomButtons/Button.js";


import "assets/css/taki.css"


export default function AdminNavbar(props) {
    const firebase=useFirebaseApp();

    const cerrarSesion = ()=>{
        firebase.auth().signOut()
    }



  return (
      
    <div className="header">
        <div className="return">
          <a href={"https://taki.club?nav=productos"+props.tienda}>
            <Button color="info" >
              Regresar
            </Button>
          </a>
        </div>
        <div className="logout">
          <Button color="info" onClick={cerrarSesion}>
            Cerrar Sesión
          </Button>
        </div>
        <div className='navTitle'>Taki</div>
    </div>
  );
}
