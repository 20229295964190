import React from 'react';
import ReactLoading from "react-loading";

function Loading () {
    return (
          
            <div>
              <ReactLoading id="loading" type="spin" color="orange"/>
            </div>
          
          )
        }

export { Loading };