import React from 'react';

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import "assets/css/taki.css"

const useStyles = makeStyles(styles);

const DropDown = (props) => {

    const [multipleSelect, setMultipleSelect] = React.useState([]);
    const handleMultiple = event => {
        setMultipleSelect(event.target.value);
        props.seleccion(event.target.value);
      };

    const classes = useStyles();
    return (
        <div id="formControl">
            <FormControl
                fullWidth
                className={classes.selectFormControl}
                >
                <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                >
                    Selección de tiendas
                </InputLabel>
                <Select
                    multiple
                    value={multipleSelect}
                    onChange={handleMultiple}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                    name: "multipleSelect",
                    id: "multiple-select"
                    }}
                >
                    <MenuItem
                    disabled
                    classes={{
                        root: classes.selectMenuItem
                    }}
                    >
                    Seleccione las tiendas en las que cargará los productos
                    </MenuItem>
                    {props.listaTiendas.map((elemento,index)=>{
                        var el=elemento[Object.keys(elemento)[0]]
                        return(
                            <MenuItem 
                            key={index}
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={el.key}>
                                {el.tienda}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
     );
}
 
export default DropDown;