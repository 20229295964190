import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebaseConfig from "firebase-config"
import {FirebaseAppProvider} from 'reactfire';
import {Loading} from "components/Taki/Loading"

const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);
const tienda=parsed['biz'];

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Suspense fallback={<Loading/>}>
        <App tienda={tienda}/>        
      </Suspense>

    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
